<template>
  <div class="container">
    <div class="content">
      <div class="row" style="margin-top: 60px">
        <div class="col-xs-12 col-md-8 col-md-offset-2">
          <h1>{{ content[id].tittel }}</h1>
          <br />
          <div v-if="content[id].image"><img :src="require(`@/assets/images/${content[id].image}`)" /><br /></div>
          <span v-if="content[id].text1" v-html="content[id].text1"></span><br /><br />

          <b>PRAKTISK:</b><br />
          <span v-if="content[id].practical" v-html="content[id].practical"></span>
          <span v-if="content[id].text2" v-html="content[id].text2"></span>
          <span v-if="content[id].text2"><br /><br /></span>

          <span v-if="content[id].varighet"> <i>Varighet:</i> {{ content[id].varighet }}<br /></span>
          <span v-if="content[id].antall"> <i>Antall:</i> {{ content[id].antall }}<br /></span>
          <span v-if="content[id].praktisk"> <i>Praktisk:</i> {{ content[id].praktisk }}<br /></span>
          <br />
          <span v-if="content[id].text3" v-html="content[id].text3"></span>
          <div v-if="content[id].text3"></div>

          <br /><br />
          <router-link class="button large-btn secondary" :to="`/#${id}`">Tilbake</router-link>
          <router-link class="button large-btn" :to="`/kontakt/${id}`">Send forespørsel</router-link>
          <br /><br /><br /><br /><br /><br /><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data: () => ({
    content: {
      konferansier: {
        tittel: "ANDREAS WAHL SOM KONFERANSIER",
        text1: "Andreas har betydelig erfaring i å lede arrangementer fra scene. Han leder tekniske og faglige konferanser såvel som folkelige utendørsarrangementer.",
        text2: "Passer for alle typer og størrelser på arrangement som vil tilføre humor og vitenskap.",
        varighet: "fleksibel",
        antall: "fra 2 personer og oppover",
        praktisk: "Innhold og form tilpasses arrangmentet. Krever hodebøylemikrofon",
        text3: "Norsk eller engelsk<br>Kan kombineres med show"
      },
      energisk: {
        image: "energisk.jpg",
        tittel: "ENERGISK– ET VITENSHOW OM ENERGI",
        text1:
          "De som tror realfagene er kjedelige og fastlåste må tro om igjen. ENERGISK er en krysning mellom stand-up, foredrag, trylleshow og rockekonsert, og viser naturvitenskap som underholdning. Se sprit, appelsiner og elektroner med nye øyne. På 45 min. utforsker Andreas begrepet «energi» sammen med publikum, gjennom triks og tankevekkere om klima.",
        text2:
          "Vitenshowet krever ikke annet enn tilgang til projektor, en bøtte med vann og en mopp til å tørke opp vannsølet etterpå. Alt annet medbringes. Showet er egnet for forsamlinger fra 30-400 personer, og kan tilpasses de fleste typer lokaler.",
        varighet: "45 min",
        antall: "",
        praktisk: "Krever en bøtte med vann og en mopp. Projektor og lerret, om mulig.",
        text3: ""
      },
      seoikult: {
        image: "kult.jpg",
        tittel: "SE!OI!KULT!",
        text1:
          "Se!Oi!Kult! er et inspirasjonsforedrag for lærere eller barnehageansatte. Målet er å hjelpe den enkelte lærer til å skape opplevelser med elevene som skaper nysgjerrighet og forventninger. Andreas lærer bort sine favorittdemonstrasjoner, og hvorfor han mener de har sin plass i klasserommet/barnehagen.",
        text2: "",
        varighet: "fleksibel",
        antall: "",
        praktisk: "Krever scene på min. 2 x 6 x 2,5 meter",
        text3: "Norsk eller engelsk"
      },
      vannvittig: {
        image: "",
        tittel: "VANNVITTIG VITENSKAP",
        text1:
          "VannVittigVitenskap er nerdete teambuilding. Etter en kort intro med futt fra Andreas deles deltagerne i grupper og utfordres til å løse underholdende forskningsoppgaver på veldig lavt nivå.  Det kan være å sende opp brødristerluftballong, tre på kollega badehette med hjelp av vannkraft, lage røykringer, imploderende ølboks, skyte potetkanon osv. På hver post får gruppene poeng i form av mentos-pastiller. Disse må ikke spises, men bevares til den store finalen der de kommer til nytte i en fuktig og smått spektakulær avslutning.<br><br />Andreas leder hele aktiviteten, og sørger for spenning og eksperimentglede fra første  til siste sekund. ",
        practical:
          "Vannvittig Vitenskap passer for større og mindre grupper, og krever tilgang til innendørs og utendørs lokaliteter.<br><br><b>Antall:</b>  20-200 personer<br><b>Varighet: </b>  60-90 minutter<br><b>Areal:</b>  min 100 m2Takhøyde:  min 4 meter<br><b>Rigg: </b>Opprigg 60 minutter, nedrigg 40 minutter. <br><b>Annet:</b> Tilgang til strøm og vann"
      },
      universet: {
        image: "",
        tittel: "UNIVERSET PÅ 42 MINUTTER",
        text1: "Universet på 42 minutter et vitenshow som tar for seg universet vi lever i fra dets begynnelse og frem til de minste bestanddelene vi kjenner i dag.",
        text2: "",
        varighet: "42 minutter + ev. tid til spørsmål",
        antall: "30-400 personer",
        praktisk: "",
        text3: "Norsk eller engelsk"
      },
      joss: {
        image: "",
        tittel: "JØSS‽",
        text1:
          "Jøss‽ handler om å fekte med funfacts og skape inspirerende realfag og gi elevene forventninger. Målet er å hjelpe den enkelte lærer til å skape et naturfag som elevene gleder seg til.",
        text2: "",
        varighet: "fleksibelt",
        antall: "",
        praktisk: "Krever scene på min. 2 x 6 x 2,5 meter",
        text3: "Norsk eller engelsk"
      }
    }
  })
};
</script>

<style lang="scss"></style>
